import React from "react"
import styled from "@emotion/styled"
import dimensions from "styles/dimensions";
import colors from "styles/colors";

const LogoContent = styled("div")`
  text-decoration: none;
  color: ${colors.pink500};
  font-size: 28px;
  font-weight: 800;

  @media(max-width: ${dimensions.maxwidthMobile}px) {
    font-size: 20px;
}
`

const Logo = () => <LogoContent>Jillian Keenan</LogoContent>

export default Logo
